@import "assets/scss/toast-override.scss";
@import "assets/scss/fonts.scss";
@import "assets/scss/autosuggest.scss";

#root {
  min-height: 100%;
}

body {
  background-color: #e9f0ff !important;
}

.page-single {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem 0;
  height: 100vh;
}

.login-logo {
  height: 80px;
}

.system-menu {
  .nav-tabs {
    border-bottom: 0;
  }

  .nav-tabs .nav-link {
    padding: 0;
  }
}

.system-body {
  .page-header {
    margin: 1rem 0 1rem;
  }

  .icon-action {
    font-size: 1.2rem;
  }

  .icon {
    color: #282828 !important;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: grid;
  align-content: center;
  justify-items: center;
  z-index: 9998;

  .loader {
    margin: 0 auto;
  }
}

textarea.form-control {
  padding: 0.28rem 0.75rem;
}

.datepicker {
  .form-control.custom-select {
    width: 20%;

    &:nth-child(2) {
      width: 40%;
    }

    &:last-child {
      width: 25%;
    }
  }
}

.status-circle {
  border-radius: 100px;
  width: 25px;
  height: 25px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.75);
}

.active-row {
  background-color: green;
}

.desactive-row {
  background-color: red;
}

.center-align {
  margin: auto auto;
  text-align: center;
}

.empty-data-message {
  font-size: 18px;
  font-style: italic;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
}

.isActive-indicator {}

.calls-board-container {
  .nav-tabs .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }

  .call-card {
    h1 {
      font-size: 2rem;
    }
  }
}

.multiselect-react-dropdown {
  .highlight {
    margin: 0;
    border: unset;
    border-radius: unset;
    max-height: unset;
    overflow: auto;
  }
}

.input-group-text {
  width: 45px;
  text-align: center;

  svg {
    margin: 0 auto;
  }
}

.page-quantity-container {
  .input-group-text {
    width: 80px;
  }
}

.system-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.system-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(147, 147, 147, 0.385);
}

.system-modal.answer-modal,
.system-modal.incomingcall-modal {
  position: fixed;
  top: unset;
  left: 10px;
  right: unset;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.system-overlay.answer-overlay,
.system-overlay.incomingcall-overlay {
  position: unset;
  background-color: unset;
}

.system-overlay.answer-overlay .card.system-modal-card,
.system-overlay.incomingcall-overlay .card.system-modal-card {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .4);
  background: #f5f3f3;
}

.system-overlay.answer-overlay .card.system-modal-card {
  box-shadow: 0 2px 3px rgba(0, 0, 0, .4);
  background: #d8f0c1;
}

.system-overlay.answer-overlay .card.system-modal-card:hover {
  opacity: 0.7;
  transition: 1s;
}

.answer-title {
  color: #265002;
  font-weight: bold;
}

.answer-title .icon-phone {
  font-size: 24px;
}

.answer-title .icon-phone-20 {
  font-size: 20px;
  color: #cd201f;
}

.end-container {
  transition: 1s;
  /*opacity: 0;*/
  color: #cd201f;
}

.end-container:hover {
  opacity: 0.7;
}

.card-title {
 /* white-space: nowrap;*/
  overflow: hidden;
}

.display-block {
  display: block !important;
}

.btn-success-helpline {
  background-color: #265002;
  border-color: #265002 1px solid;
}

.btn-danger-helpline {
  background-color: rgb(191, 9, 24);
  border-color: rgb(191, 9, 24);
  color: white;
}

.card.system-modal-card {
  width: auto;
  margin: 0;
  min-width: 400px;
}

.field-address {
  flex-grow: 2 !important;
}

.field-address-data {
  flex-grow: 2 !important;
  font-size: small !important;
}

.field-city {
  flex-grow: 0.65 !important;
}

.field-city-data {
  flex-grow: 0.65 !important;
  font-size: small !important;
}

.field-zipcode {
  flex-grow: 0.35 !important;
}

.field-zipcode-data {
  font-size: small !important;
  flex-grow: 0.35 !important;
}

.board-header-search {
  .input-icon-addon {
    z-index: 2;
  }

  .form-control {
    width: 40px;
    padding-right: 0 !important;
    padding-left: 2.5rem;
  }

  &.active-search {
    h3 {
      display: none;
    }

    .input-icon {
      width: 100%;
    }

    .form-control {
      width: 100%;
      padding-right: 0.75rem !important;
    }
  }
}

.details-label {
  background-color: #f8f9fa;
  opacity: 1;
  display: block;
  width: 100%;
  min-height: 36.5px;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker-wrapper {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.border-none {
  border: none !important;
}

.cardBodyBilling {
  min-height: 84vh !important;
}

.payment-rejected {
  text-decoration: line-through;
}

.call-answer-active {
  animation-fill-mode: forwards !important;
  animation-direction: alternate !important;
  animation-name: loading;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
}

@keyframes loading {
  0% {
    opacity: 0.3;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.9;
  }
}

.badgeNotification {
  display: inline-block;
  background-color: lighten(#467fcf, 20%);
  border-radius: 50% !important;
  color: #fff;
  padding: 5px 10px !important;
  position: relative;
  cursor: pointer;
  font-size: 16px;
}

.badgeNotificationAlert {
  display: inline-block;
  background-color: lighten(red, 20%);
  border-radius: 50% !important;
  color: #fff;
  padding: 5px 10px !important;
  position: relative;
  cursor: pointer;
  font-size: 16px;
}

.pulsate::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  animation-play-state: running;
  border-radius: 50%;
  border: 4px double lighten(red, 20%);
  color: white;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.datepicker {
  height: 2.375rem;
  font-size: 0.9375rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  padding: 0 0 0 10px;
}

.notifications-modal {
  max-height: 500px;
  overflow-y: scroll;
}

.notifications_hr {
  margin: 10px 0 10px 0;
}

.notifications_container {
  margin: 0 0 20px 0;
}

.notifications_line {
  font-size: 16px;
  cursor: pointer;
}

.notifications_line:hover {
  background-color: lighten(#467fcf, 20%);
  color: white;
}

.card-header.alert-danger {
  background-color: #f5d2d2;
}

.header {
  .header-brand-img {
    height: 4rem;
    margin: 0;
  }

  .header-brand:hover {
    opacity: 1;
  }
}

.vertical-align {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.background-staging {
  background-color: #FFCCBC !important;
}

.background-datosbasicos {
  background-color: #F2FBE2 !important;

}

.background-datosmedicos {
  background-color: #FBE3E2 !important;
}

.background-datospagos {
  background-color: #FBE3E2 !important;
}

.background-datosempresa {
  background-color: #E7FBF3 !important;
}

.background-datosaparatos {
  background-color: #E2E9FB !important;
}

@media (max-width: 575px) {
  .col-xs-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .col-xs-6 {
    flex: 0 0 100% !important;
    max-width: 50% !important;
  }
}