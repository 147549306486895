  .react-autosuggest__container {
      position: relative;
  }

  .react-autosuggest__input:focus {
      outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
      border-bottom-left-radius : 0;
      border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
      display: none;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
      display                   : block;
      position                  : absolute;
      top                       : 38px;
      width                     : 100%;
      border                    : 1px solid #aaa;
      background-color          : #fff;
      border-bottom-left-radius : 4px;
      border-bottom-right-radius: 4px;
      z-index                   : 99;
  }

  .react-autosuggest__suggestions-list {
      margin         : 0;
      padding        : 0;
      list-style-type: none;
  }

  .react-autosuggest__suggestion {
      cursor : pointer;
      padding: 4px 20px;

      .suggestion-item {
          display        : flex;
          justify-content: center;
          align-items    : center;
      }
  }

  .react-autosuggest__suggestion:not(:first-child) {
      border-top: 1px solid #ddd;
  }

  .react-autosuggest__suggestion--highlighted {
      background-color: #467fcf;
      color           : #fff;

      .highlight {
          color: #120000;
      }
  }

  .suggestion-content {
      display          : flex;
      align-items      : center;
      background-repeat: no-repeat;
  }

  .name {
      margin-left: 68px;
      line-height: 45px;
  }

  .highlight {
      color      : #ee0000;
      font-weight: bold;
  }